/* eslint @typescript-eslint/no-non-null-assertion: 0*/
import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { RouterRoutes, RouterRoutesEnum, whitelist } from '../utils/RouterRoutes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  LoginRequest,
  LoginResponse,
  ILoginRequest,
  ILoginWithOtpRequest,
  LoginWithOtpResponse,
  LoginWithOtpRequest,
  LoginWithIdNumberRequest,
  LoginWithIdNumberResponse,
  LoginResponseStatusEnums,
  ILoginWithIdNumberRequest,
  IRegisterCustomerWithDetailsRequest,
  RegisterCustomerWithDetailsResponse,
  RegisterCustomerWithDetailsRequest,
} from '../clients/AccountClient';
import SessionDialog from '../components/SessionDialog/SessionDialog';
import { SessionUtils } from '../components/SessionDialog/SessionUtils';
import useAccountClient from '../hooks/account/Client';
import { useTracking } from '../Tracking/TrackingContext';
import useLoanClient from '../hooks/loan/Client';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { useSnackBar } from './SnackBarContext';

interface AuthContextProviderProps {
  children: React.ReactNode | React.ReactFragment | JSX.Element;
}

interface AuthTokenProps extends JwtPayload {
  account_id: string;
}

export const AuthContext = React.createContext({
  isAuthenticated: false,
  /* eslint-disable  @typescript-eslint/no-unused-vars*/
  /* When initially created the empty promises are fine, they are needed here in the definition to prevent build errors.
    When used resolve and reject logic can be implemented.*/
  login: (request: ILoginRequest) => {
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
    return new Promise<LoginResponse>((resolve, reject) => {
      // do nothing
    });
  },
  loginWithOtp: (request: ILoginWithOtpRequest) => {
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
    return new Promise<LoginWithOtpResponse>((resolve, reject) => {
      // do nothing
    });
  },
  loginWithIdNumber: (request: ILoginWithIdNumberRequest) => {
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
    return new Promise<LoginWithIdNumberResponse>((resolve, reject) => {
      // do nothing
    });
  },
  register: (request: IRegisterCustomerWithDetailsRequest) => {
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
    return new Promise<RegisterCustomerWithDetailsResponse>((resolve, reject) => {
      // do nothing
    });
  },
  logout: () => {
    // do nothing
  },
});

export const useAuthentication = () => {
  return useContext(AuthContext);
};

let refreshTokenTimeout: NodeJS.Timeout | undefined = undefined;
let popupTimeout: NodeJS.Timeout | undefined = undefined;

const AuthContextProvider = (props: AuthContextProviderProps) => {
  const client = useAccountClient();
  const loanClient = useLoanClient();
  const navigate = useNavigate();
  const { EventTrack, TrackAccountId, SetExistingCustomer, SetIsPersonalDetailsComplete } = useTracking();
  const { displaySnackBar } = useSnackBar();
  const location = useLocation();
  const [openDialog, setOpenDialog] = React.useState(false);

  const clearSession = useCallback(() => {
    SetExistingCustomer(false);
    SetIsPersonalDetailsComplete(false);
    const blackbox = localStorage.getItem('blackbox');

    localStorage.clear();

    if (blackbox !== null) localStorage.setItem('blackbox', blackbox);

    setOpenDialog(false);

    clearTimeouts();
  }, [SetExistingCustomer, SetIsPersonalDetailsComplete]);

  const authTokenValid = (): boolean => {
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      const decodedToken = jwtDecode<JwtPayload>(storedToken);

      const nowEpochInSeconds = Math.floor(Date.now() / 1000);

      return decodedToken.exp !== null && decodedToken.exp !== undefined && decodedToken.exp > nowEpochInSeconds;
    }

    return false;
  };

  const authTokenExists = (): boolean => {
    return localStorage.getItem('token') !== null;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(() => authTokenValid());

  const logout = useCallback(() => {
    displaySnackBar("You've been logged out.", 'success');
    clearSession();
    setIsAuthenticated(false);
    navigate(`${RouterRoutes.home}`);
  }, [clearSession, displaySnackBar, navigate]);

  const visibilityChangeHandler = async () => {
    if (document.visibilityState === 'visible' && authTokenExists() && !authTokenValid()) {
      logout();
    }
  };

  useEffect(() => {
    if (authTokenExists() && !authTokenValid()) {
      logout();
    }

    const token = localStorage.getItem('token');
    if (token !== null && authTokenValid()) {
      const decodedToken = jwtDecode<AuthTokenProps>(token);

      // decodedToken.exp is in seconds.
      let tokenExpiryEpochInSeconds;
      if (decodedToken.exp) {
        tokenExpiryEpochInSeconds = decodedToken.exp;

        // If expiry is within a minute, log user out
        if (tokenExpiryEpochInSeconds - Math.floor(Date.now() / 1000) < 60) {
          logout();
        } else if (tokenExpiryEpochInSeconds - Math.floor(Date.now() / 1000) < 300) {
          TrackAccountId(decodedToken.account_id);
          // If the token expires within 5 minutes (or 300 seconds), refresh it
          refreshToken();
        } else {
          TrackAccountId(decodedToken.account_id);
          // Otherwise, schedule refresh token to 5 minutes before the expiry
          refreshTokenTimeout = setTimeout(
            refreshToken,
            SessionUtils.millisecondsUntilFiveMinutesBeforeExp(tokenExpiryEpochInSeconds)
          );
        }
      }
    }

    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      window.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleDialogClose = (event: {}, reason: string) => {
    // Don't close dialog
    // TODO: This should leave the dialog displayed when the backdrop is clicked. Doesn't work. Might remove.
    // As a result, clicking on the backdrop restarts the user activity.
    if (reason && reason === 'backdropClick') {
      return;
    }
    setOpenDialog(false);
  };

  const resetIdleTimer = useCallback(() => {
    console.log('Im active!');
    clearTimeout(popupTimeout);

    // Display popup 1 minute before auto-logout time. After 14 minutes of inactivity
    popupTimeout = setTimeout(displayDialog, 840000);

    // Useful when 'Yes' is clicked
    setOpenDialog(false);
  }, [displayDialog]);

  const onUserActive = useCallback(() => {
    resetIdleTimer();
  }, [resetIdleTimer]);

  // If the user goes to a different page, reset the timer (inactivity timer)
  useEffect(() => {
    // In case a user tries to access a protected route without being
    // logged in, the event should not be set
    // Without this check, the event would set, because before the user is redirected to /login, this
    // useEffect is executed.
    if (isAuthenticated) {
      let preventReset = false;
      for (const path of whitelist) {
        if (path === location.pathname) {
          preventReset = true;
        }
      }

      if (preventReset) {
        return;
      }

      // Don't record user activity on non-existent pages
      const routesEnum = Object.values(RouterRoutesEnum);
      const routesString: string[] = [];

      routesEnum.forEach((value, index) => {
        routesString.push(value);
      });

      if (!routesString.includes(location.pathname)) preventReset = true;

      if (preventReset) {
        return;
      }

      // The first time this just registers the event, and doesn't start the activity counter
      console.log('New Page. Restart Inactivity counter');
      window.addEventListener('click', onUserActive);
      window.addEventListener('oncontextmenu', onUserActive);
      window.addEventListener('paste', onUserActive);
      window.addEventListener('input', onUserActive);
      window.addEventListener('touchstart', onUserActive);
      window.addEventListener('touchmove', onUserActive);
      // TODO: Add more event listeners

      // cleanup. Every time the event is executed
      return () => {
        if (popupTimeout) {
          clearTimeout(popupTimeout);
          window.removeEventListener('click', onUserActive);
          window.removeEventListener('oncontextmenu', onUserActive);
          window.removeEventListener('paste', onUserActive);
          window.removeEventListener('input', onUserActive);
          window.removeEventListener('touchstart', onUserActive);
          window.removeEventListener('touchmove', onUserActive);
        }
      };
    }
  }, [isAuthenticated, location, onUserActive]);

  function clearTimeouts() {
    clearTimeout(refreshTokenTimeout);
    clearTimeout(popupTimeout);
  }

  const refreshToken = useCallback(async () => {
    try {
      const response = await client.refreshToken();

      if (response.success) {
        // Overwrite current token
        localStorage.setItem('token', response.token!);

        // Set timeout until the next refresh token
        const timeUntilTokenRefresh = SessionUtils.calculateTimeUntilTokenRefresh(response.tokenExpiry);
        clearTimeout(refreshTokenTimeout);
        refreshTokenTimeout = setTimeout(refreshToken, timeUntilTokenRefresh);
      }
    } catch (e) {
      console.log(e);
      displaySnackBar('Error trying to refresh session', 'error');
    }
  }, [client, displaySnackBar]);

  const setTimeoutUntilTokenRefresh = useCallback(
    (tokenExpiry: string | undefined) => {
      if (tokenExpiry !== undefined) {
        // Set timeout until the next refresh token
        const timeUntilTokenRefresh = SessionUtils.calculateTimeUntilTokenRefresh(tokenExpiry);
        refreshTokenTimeout = setTimeout(refreshToken, timeUntilTokenRefresh);
      }
    },
    [refreshToken]
  );

  const registerAsync = useCallback(
    async (request: IRegisterCustomerWithDetailsRequest): Promise<RegisterCustomerWithDetailsResponse> => {
      try {
        const body = new RegisterCustomerWithDetailsRequest(request);

        const response = await client.registerWithCustomerDetails(body);

        if (response.success) {
          localStorage.setItem('token', response.token!);
          setIsAuthenticated(true);
          TrackAccountId(response.accountId!);
          EventTrack('Registered', { value: true });
          setTimeoutUntilTokenRefresh(response.tokenExpiry);

          onUserActive();
        }
        return response;
      } catch (error) {
        console.error(error);
      }

      const defaultResponse = new RegisterCustomerWithDetailsResponse();
      defaultResponse.success = false;
      return defaultResponse;
    },
    [EventTrack, TrackAccountId, client, onUserActive, setTimeoutUntilTokenRefresh]
  );

  const loginAsync = useCallback(
    async (request: ILoginRequest): Promise<LoginResponse> => {
      try {
        const response = await client.login(new LoginRequest(request));

        if (response.success && response.status !== LoginResponseStatusEnums.MigrationPending) {
          localStorage.setItem('token', response.token!);
          setIsAuthenticated(true);
          TrackAccountId(response.accountId!);
          setTimeoutUntilTokenRefresh(response.tokenExpiry);
          onUserActive();
        }

        return response;
      } catch (error) {
        console.error(error);
        const defaultResponse = new LoginResponse();
        defaultResponse.success = false;
        return defaultResponse;
      }
    },
    [TrackAccountId, client, onUserActive, setTimeoutUntilTokenRefresh]
  );

  const loginWithOtp = useCallback(
    async (request: ILoginWithOtpRequest): Promise<LoginWithOtpResponse> => {
      console.log(request);
      console.log(new LoginWithOtpRequest(request));
      try {
        const response = await client.loginWithOtp(new LoginWithOtpRequest(request));

        if (response.success) {
          localStorage.setItem('token', response.token!);
          setIsAuthenticated(true);
          TrackAccountId(response.accountId!);
          setTimeoutUntilTokenRefresh(response.tokenExpiry);
          onUserActive();
        }

        return response;
      } catch (error) {
        console.error(error);
        const defaultResponse = new LoginWithOtpResponse();
        defaultResponse.success = false;
        return defaultResponse;
      }
    },
    [TrackAccountId, client, onUserActive, setTimeoutUntilTokenRefresh]
  );

  const loginWithIdNumber = useCallback(
    async (request: ILoginRequest): Promise<LoginWithIdNumberResponse> => {
      try {
        const response = await client.loginWithIdNumber(new LoginWithIdNumberRequest(request));

        if (response.success) {
          localStorage.setItem('token', response.token!);
          setIsAuthenticated(true);
          TrackAccountId(response.accountId!);
          setTimeoutUntilTokenRefresh(response.tokenExpiry);
          onUserActive();
        }

        return response;
      } catch (error) {
        console.error(error);
        const defaultResponse = new LoginWithIdNumberResponse();
        defaultResponse.success = false;
        return defaultResponse;
      }
    },
    [TrackAccountId, client, onUserActive, setTimeoutUntilTokenRefresh]
  );

  const checkIfExistingCustomer = useCallback(async () => {
    if (isAuthenticated) {
      const response = await loanClient.getOpenLoanDetails();
      SetExistingCustomer(response.hasClosedLoan ?? false);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, loanClient]);

  const getPersonalDetails = useCallback(async () => {
    if (isAuthenticated) {
      const response = await client.getPersonalDetails();
      SetIsPersonalDetailsComplete(response.personalDetailsIsComplete ?? false);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, client]);

  useEffect(() => {
    checkIfExistingCustomer();
  }, [checkIfExistingCustomer]);

  useEffect(() => {
    getPersonalDetails();
  }, [getPersonalDetails]);

  const values = useMemo(() => {
    return {
      isAuthenticated,
      register: registerAsync,
      login: loginAsync,
      logout: logout,
      loginWithOtp,
      loginWithIdNumber,
    };
  }, [isAuthenticated, loginAsync, loginWithIdNumber, loginWithOtp, logout, registerAsync]);

  return (
    <>
      <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>

      <SessionDialog
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        restartIdleTimer={resetIdleTimer}
        logout={logout}
      />
    </>
  );
};

/* eslint-disable  @typescript-eslint/no-explicit-any*/
export const RequireAuth = (props: any) => {
  const { isAuthenticated } = useAuthentication();
  const location = useLocation();
  const state = { path: location.pathname };
  return isAuthenticated ? props.children : <Navigate to={`/${RouterRoutes.login}`} replace={true} state={state} />;
};
export default AuthContextProvider;
