import { OpenLoanStatus } from "../../clients/LoanClient";
import { ApplicationStatus } from "../Enums/ApplicationStatus";

export const getPage = (applicationStatus: ApplicationStatus): string => {
    switch (applicationStatus) {
        case ApplicationStatus.resume:
            return "/resume";
        case ApplicationStatus.processing:
            return "/processing";
        case ApplicationStatus.readyToSign:
            return "/apply-accept";
        case ApplicationStatus.pendingIv:
            return "/pending-iv";
        case ApplicationStatus.thanks:
            return "/accept";
        case ApplicationStatus.declined:
            return "/decline";
        case ApplicationStatus.counterOffer:
            return "/apply-counter-offer";
        case ApplicationStatus.processingCounterOffer:
            return "/processing-counter-offer";
        case ApplicationStatus.error:
            return "/error";
        case ApplicationStatus.invalidBankDetails:
            return "/invalidBankDetails";
        case ApplicationStatus.invalidIncome:
            return "/invalidIncome";
        case ApplicationStatus.timeout:
            return "/timeout";
        default:
            return applicationStatus.toString();
    }
};

export const appendMember = (track: string, isExistingCustomer: boolean): string => {
    if (isExistingCustomer) {
        return track + "-member";
    }
    return track;
};

export const getPageKeyBasedOnOpenLoanStatus = (openLoanStatus: OpenLoanStatus): string => {
    switch (openLoanStatus) {
        case 0:
            return "timeout";
        case 1:
            return "pending-loan-agreement";
        case 2:
            return "pending-document-upload";
        case 3:
            return "pending-iv";
        case 4:
            return "pending-mandate-accept";
        case 5:
            return "cashout";
        case 8:
            return "pending-counter-offer";
        default:
            return '';
    }
};