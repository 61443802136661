import React, { lazy } from 'react';
import {
  L0JourneyRoutes,
  LNJourneyRoutes,
  LNJourneyShortenedRoutes,
  PostProcessingRoutes,
} from '../../../utils/RouterRoutes';

import { defaultAppSettings } from '../../../contexts/AppSettingsContext';

const DocumentUploadOld = lazy(() => import('../../../components/DocumentUpload/DocumentUploadOld'));
const DocumentUploadNew = lazy(() => import('../../../components/DocumentUpload/DocumentUploadNew'));

const AddressDetailsPage = lazy(() => import('../JourneyPages/AddressDetails'));
const AffordabilityDetailsPage = lazy(() => import('../JourneyPages/AffordibilityDetails'));
const BankDetailsPage = lazy(() => import('../JourneyPages/BankDetails'));
const OtpPage = lazy(() => import('../JourneyPages/Otp'));
const EmploymentDetailsPage = lazy(() => import('../JourneyPages/EmploymentDetails'));
const CustomerDetailsPage = lazy(() => import('../JourneyPages/CustomerDetails'));
const ConfirmCustomerDetails = lazy(() => import('../JourneyPages/LN/ConfirmCustomerDetails/ConfirmCustomerDetails'));
const ConfirmAffordabilityDetailsPage = lazy(() => import('../JourneyPages/LN/ConfirmAffordabilityDetails/ConfirmAffordabilityDetails'));
const ConfirmDetailsSummary = lazy(() => import('../JourneyPages/LN/ConfirmDetailsSummary/ConfirmDetailsSummary'));
const Processing = lazy(() => import('../JourneyPages/Processing'));
const TermsAndConditionsPage = lazy(() => import('../JourneyPages/Decisions/Approved/TermsAndConditions'));
const Declined = lazy(() => import('../JourneyPages/Decisions/Declined/Declined'));
const CounterofferProcessing = lazy(() => import('../JourneyPages/Decisions/counteroffer/CounterofferProcessing'));
const CounterOffer = lazy(() => import('../../MyLoan/AccountSummary/CounterOffer/CounterOffer'));
const CounterOfferDeclined = lazy(() => import('../JourneyPages/Decisions/Declined/CounterOfferDeclined'));
const CreditLifePlus = lazy(() => import('../JourneyPages/VAS/CreditLifePlus/CreditLifePlus'));
const CreditLifePlusBeneficiary = lazy(() => import('../JourneyPages/VAS/CreditLifePlus/CreditLifePlusBeneficiary'));
const CreditLifeLoanRepaymentDetails = lazy(() => import('../JourneyPages/VAS/CreditLifePlus/CreditLifeLoanRepaymentDetails'));

export interface JourneyConfig<RouteTypeEnum> {
  path: RouteTypeEnum;
  element: React.ReactElement;
}

export const l0journey: JourneyConfig<L0JourneyRoutes>[] = [
  { path: L0JourneyRoutes.otp, element: <OtpPage /> },
  { path: L0JourneyRoutes.details, element: <CustomerDetailsPage /> },
  { path: L0JourneyRoutes.address, element: <AddressDetailsPage /> },
  { path: L0JourneyRoutes.employment, element: <EmploymentDetailsPage /> },
  { path: L0JourneyRoutes.affordability, element: <AffordabilityDetailsPage /> },
  { path: L0JourneyRoutes.bank, element: <BankDetailsPage /> },
  { path: L0JourneyRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: L0JourneyRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: L0JourneyRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: L0JourneyRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== L0JourneyRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== L0JourneyRoutes.creditLifePlus &&
      x.path !== L0JourneyRoutes.creditLifePlusBeneficiary &&
      x.path !== L0JourneyRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const lnJourney: JourneyConfig<LNJourneyRoutes>[] = [
  { path: LNJourneyRoutes.otp, element: <OtpPage /> },
  { path: LNJourneyRoutes.details, element: <CustomerDetailsPage /> },
  { path: LNJourneyRoutes.address, element: <AddressDetailsPage /> },
  { path: LNJourneyRoutes.employment, element: <EmploymentDetailsPage /> },
  { path: LNJourneyRoutes.affordability, element: <AffordabilityDetailsPage /> },
  { path: LNJourneyRoutes.bank, element: <BankDetailsPage /> },
  { path: LNJourneyRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: LNJourneyRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: LNJourneyRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: LNJourneyRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== LNJourneyRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== LNJourneyRoutes.creditLifePlus &&
      x.path !== LNJourneyRoutes.creditLifePlusBeneficiary &&
      x.path !== LNJourneyRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const lnJourneyShortened: JourneyConfig<LNJourneyShortenedRoutes>[] = [
  { path: LNJourneyShortenedRoutes.confirmDetails, element: <ConfirmDetailsSummary /> },
  { path: LNJourneyShortenedRoutes.updateDetails, element: <ConfirmCustomerDetails /> },
  { path: LNJourneyShortenedRoutes.updateAffordability, element: <ConfirmAffordabilityDetailsPage /> },
  { path: LNJourneyShortenedRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: LNJourneyShortenedRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: LNJourneyShortenedRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: LNJourneyShortenedRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== LNJourneyShortenedRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== LNJourneyShortenedRoutes.creditLifePlus &&
      x.path !== LNJourneyShortenedRoutes.creditLifePlusBeneficiary &&
      x.path !== LNJourneyShortenedRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const PostProcessing: JourneyConfig<PostProcessingRoutes>[] = [
  { path: PostProcessingRoutes.agreement, element: <TermsAndConditionsPage /> },
  { path: PostProcessingRoutes.documentUpload, element: defaultAppSettings.pageToggles.newDocumentUploadPage ? < DocumentUploadNew /> : <DocumentUploadOld /> },
  { path: PostProcessingRoutes.declined, element: <Declined /> },
  { path: PostProcessingRoutes.counterOffer, element: <CounterOffer /> },
  { path: PostProcessingRoutes.counterOfferDecline, element: <CounterOfferDeclined /> },
  { path: PostProcessingRoutes.counterOfferProcessing, element: <CounterofferProcessing /> },
];
