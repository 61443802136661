import { Route, Routes, useLocation } from 'react-router-dom';
import TrackingContextProvider from './Tracking/TrackingContext';
import AppBar from './components/AppBar/AppBar';
import WongaFooter from './components/Footer/WongaFooter';
import UrgentNotificationBanner from './components/UrgentNotificationBanner/UrgentNotificationBanner';
import { AppSettingsContextProvider } from './contexts/AppSettingsContext';
import AuthContextProvider, { RequireAuth } from './contexts/AuthContext';
import LimiterProvider from './contexts/LimiterContext';
import PaymentDialogProvider from './contexts/PaymentDialogContext';
import SeoContextProvider from './contexts/SeoContext';
import SnackBarProvider from './contexts/SnackBarContext';
import { RouterRoutes } from './utils/RouterRoutes';
import { PostProcessing, l0journey, lnJourney, lnJourneyShortened } from './pages/Wizard/JourneyConfig/JourneyConfigs';
import Base from './components/Base/base';
import { lazy } from 'react';

import Home from './pages/Home/Home';
import SliderWithFlap from './components/Sliders/slider-with-flap/slider-with-flap';

const MyDetails = lazy(() => import('./pages/MyDetails/MyDetails'));

const MyLoan = lazy(() => import('./pages/MyLoan/MyLoan'));
const AccountSummary = lazy(() => import('./pages/MyLoan/AccountSummary/AccountSummary'));
const LoanDocuments = lazy(() => import('./pages/MyLoan/LoanDocuments/LoanDocument'));

const StatementPrint = lazy(() => import('./pages/MyLoan/LoanDocuments/Statements/StatementPrint/StatementPrint'));
const PaidUpLetterPrint = lazy(
  () => import('./pages/MyLoan/LoanDocuments/PaidUpLetters/PaidUpLetterPrint/PaidUpLetterPrint')
);
const LegalDocumentPrint = lazy(
  () => import('./pages/MyLoan/LoanDocuments/LegalDocuments/LegalDocumentPrint/LegalDocumentPrint')
);
const SettlementLetterPdfView = lazy(
  () => import('./pages/MyLoan/AccountSummary/SettlementLetterPdf/SettlementLetterPdfView')
);

const Register = lazy(() => import('./pages/Register/Register'));
const Login = lazy(() => import('./pages/Login/Login'));
const ConfirmIDNumber = lazy(() => import('./pages/Login/ViaOTP/ConfirmIDNumber'));
const ConfirmOTP = lazy(() => import('./pages/Login/ViaOTP/ConfirmOTP'));
const MissingCellNumber = lazy(() => import('./pages/Login/ViaOTP/MissingCellNumber'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const ForgotPasswordEmailResend = lazy(() => import('./pages/ForgotPassword/ForgotPasswordEmailResend'));
const ConfirmPasswordReset = lazy(() => import('./pages/ForgotPassword/ConfirmPasswordReset/ConfirmPasswordReset'));
const ConfirmChangeEmail = lazy(() => import('./pages/ChangeEmail/ConfirmChangeEmail/ConfirmChangeEmail'));

const Wizard = lazy(() => import('./pages/Wizard/Wizard'));
const LeadGenRedirect = lazy(() => import('./pages/Wizard/JourneyPages/LeadGen/LeadGenRedirect'));
const Limiter = lazy(() => import('./pages/Wizard/JourneyPages/Limiter'));

const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'));

function App() {
  const location = useLocation();
  const showHeaderAndFooter = !(
    location.pathname.includes(RouterRoutes.statementPrint) ||
    location.pathname.includes(RouterRoutes.paidUpLetterPrint) ||
    location.pathname.includes(RouterRoutes.legalDocumentPrint) ||
    location.pathname.includes(RouterRoutes.settlementLetterPrint)
  );

  return (
    /* eslint-disable @typescript-eslint/no-empty-function */
    <AppSettingsContextProvider>
      <TrackingContextProvider>
        <LimiterProvider>
          <SnackBarProvider>
            <AuthContextProvider>
              <SeoContextProvider>
                <UrgentNotificationBanner />

                {showHeaderAndFooter && <AppBar />}
                <Routes>
                  <Route path={RouterRoutes.home} element={<Home />}>
                    <Route path={RouterRoutes.home} element={<SliderWithFlap updateLoanValues={() => { }} />} />
                  </Route>

                  <Route path={RouterRoutes.home} element={<Base />}>
                    <Route path={RouterRoutes.limiter} element={<Limiter />} />
                    <Route path={RouterRoutes.login} element={<Login />} />
                    <Route path={RouterRoutes.confirmIdNumber} element={<ConfirmIDNumber />} />
                    <Route path={RouterRoutes.confirmOTP} element={<ConfirmOTP />} />
                    <Route path={RouterRoutes.MissingCellNumber} element={<MissingCellNumber />} />
                    <Route path={RouterRoutes.register} element={<Register />} />

                    <Route path={RouterRoutes.confirmChangeEmail + '/:pin'} element={<ConfirmChangeEmail />} />
                    <Route path={RouterRoutes.recoverPassword} element={<ForgotPassword />} />
                    <Route path={RouterRoutes.confirmPasswordReset + '/:pin'} element={<ConfirmPasswordReset />} />

                    <Route path={RouterRoutes.newJourney} element={<Wizard />}>
                      {l0journey.map((config, index) => (
                        <Route key={index} path={config.path} element={config.element} />
                      ))}
                      {PostProcessing.map((config, index) => (
                        <Route key={index} path={config.path} element={config.element} />
                      ))}
                    </Route>
                    <Route path={RouterRoutes.existingJourney} element={<Wizard />}>
                      {[...lnJourney, ...lnJourneyShortened].map((config, index) => (
                        <Route key={index} path={config.path} element={config.element} />
                      ))}
                      {PostProcessing.map((config, index) => (
                        <Route key={index} path={config.path} element={config.element} />
                      ))}
                    </Route>

                    {/* My loan */}
                    <Route
                      path={RouterRoutes.myLoan + '/*'}
                      element={
                        <RequireAuth>
                          <MyLoan />
                        </RequireAuth>
                      }
                    >
                      <Route
                        path={'*'}
                        element={
                          <PaymentDialogProvider>
                            <AccountSummary />
                          </PaymentDialogProvider>
                        }
                      />

                      <Route path={RouterRoutes.loanDocuments} element={<LoanDocuments />} />
                    </Route>

                    {/* My Profile */}
                    <Route
                      path={RouterRoutes.myDetails + '/*'}
                      element={
                        <RequireAuth>
                          <MyDetails />
                        </RequireAuth>
                      }
                    />

                    <Route path={RouterRoutes.statementPrint} element={<StatementPrint />} />
                    <Route path={RouterRoutes.paidUpLetterPrint} element={<PaidUpLetterPrint />} />
                    <Route path={RouterRoutes.legalDocumentPrint} element={<LegalDocumentPrint />} />
                    <Route path={RouterRoutes.settlementLetterPrint} element={<SettlementLetterPdfView />} />

                    <Route path={RouterRoutes.emailResend} element={<ForgotPasswordEmailResend />} />
                    <Route path={RouterRoutes.leadAccept} element={<LeadGenRedirect />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>
                </Routes>
                {showHeaderAndFooter && <WongaFooter />}
              </SeoContextProvider>
            </AuthContextProvider>
          </SnackBarProvider>
        </LimiterProvider>
      </TrackingContextProvider>
    </AppSettingsContextProvider>
  );
}

export default App;
