import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useAppSettings } from "../../contexts/AppSettingsContext";
import styles from "./UrgentNotificationBanner.module.scss";

export const notificationBannerKey = "notificationBannerDismissed";

const UrgentNotificationBanner: React.FunctionComponent = () => {
    const appSettings = useAppSettings();
    const [isNotificationBannerDismissed, setNotificationBannerDismissed] = useState(() => {
        const storedValue = localStorage.getItem(notificationBannerKey);
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const closeBanner = () => {
        setNotificationBannerDismissed(true);
        localStorage.setItem(notificationBannerKey, JSON.stringify(true));
    };

    return (
        <>
            {
                appSettings.urgentMessageConfig.urgentMessageEnabled && !isNotificationBannerDismissed ?
                    <Box sx={{ backgroundColor: "#188ece", padding: "1rem 0.5rem" }}>
                        <Typography sx={{ color: "white", textAlign: "center", fontSize: "2.1rem", fontWeight: "700", padding: "0 4rem" }}>
                            {appSettings.urgentMessageConfig.urgentMessageHeading}
                            <span className={styles["close-banner-button"]} onClick={closeBanner}>x</span>
                        </Typography>
                        <Typography sx={{ color: "white", textAlign: "center", fontWeight: "300", fontSize: "1.4rem" }}>{appSettings.urgentMessageConfig.urgentMessageText}</Typography>
                    </Box>
                    :
                    <></>
            }
        </>
    );
};

export default UrgentNotificationBanner;